// Ao adicionar ,remover ou editar qualquer unidade dessa lista a aplicação vai se adaptar automaticamente
// Regiões: Cada região nova cadastrada será automaticamente adicionado a listagem de regiões
// Modalidades: Configurar para TRUE ou FALSE as modalidades disponíveis em cada unidade
// Whatsapp: Alterar o número também atualiza automaticamente o link para o whatsapp (obs: usar sempre o padrao +551100000000 sem espaço ou traços)

// ALTO DO IPIRANGA
import altoBar from "../assets/units/alto/bar.png";
import altoChurrasqueira from "../assets/units/alto/churrasqueira.png";
import altoLoja from "../assets/units/alto/loja.png";
import altoMesanino from "../assets/units/alto/mesanino.png";
import altoMesas from "../assets/units/alto/mesas.png";
import altoQuadra_2 from "../assets/units/alto/quadra_2.png";
import altoQuadra from "../assets/units/alto/quadra.png";


// IPIRANGA
import ipiranga1 from "../assets/units/ipiranga/1.jpeg";
import ipiranga2 from "../assets/units/ipiranga/2.jpeg";
import ipiranga3 from "../assets/units/ipiranga/3.jpeg";
import ipiranga4 from "../assets/units/ipiranga/4.jpeg";
import ipiranga5 from "../assets/units/ipiranga/5.jpeg";
import ipiranga6 from "../assets/units/ipiranga/6.jpeg";
import ipiranga7 from "../assets/units/ipiranga/7.jpeg";

// SANTO ANDRE
import santoAndreBar from "../assets/units/santoandre/bar.png";
import santoAndreChurrasqueira from "../assets/units/santoandre/churrasqueira.png";
import santoAndreMesas from "../assets/units/santoandre/mesas.png";
import santoAndreParque from "../assets/units/santoandre/parque.png";
import santoAndrePrainha from "../assets/units/santoandre/prainha.png";
import santoAndreQuadra from "../assets/units/santoandre/quadra.png";

// GRANJA
import granja1 from "../assets/units/GRANJA_VIANA/1.jpg";
import granja2 from "../assets/units/GRANJA_VIANA/2.jpg";
import granja3 from "../assets/units/GRANJA_VIANA/3.jpg";
import granja4 from "../assets/units/GRANJA_VIANA/4.jpg";
import granja5 from "../assets/units/GRANJA_VIANA/5.jpg";
import granja6 from "../assets/units/GRANJA_VIANA/6.jpg";
import granja7 from "../assets/units/GRANJA_VIANA/7.jpg";

// IPIRANGA BEACH TENNIS
import ipirangaTennis1 from "../assets/units/IPIRANGA_BEACH_TENNIS/1-.jpg";
import ipirangaTennis2 from "../assets/units/IPIRANGA_BEACH_TENNIS/2.jpg";
import ipirangaTennis3 from "../assets/units/IPIRANGA_BEACH_TENNIS/3.jpg";
import ipirangaTennis4 from "../assets/units/IPIRANGA_BEACH_TENNIS/4.jpg";
import ipirangaTennis5 from "../assets/units/IPIRANGA_BEACH_TENNIS/5.jpg";
import ipirangaTennis6 from "../assets/units/IPIRANGA_BEACH_TENNIS/6.jpg";
import ipirangaTennis7 from "../assets/units/IPIRANGA_BEACH_TENNIS/7.jpg";
import ipirangaTennis8 from "../assets/units/IPIRANGA_BEACH_TENNIS/8.jpg";
import ipirangaTennis9 from "../assets/units/IPIRANGA_BEACH_TENNIS/9.jpg";
import ipirangaTennis10 from "../assets/units/IPIRANGA_BEACH_TENNIS/10.jpg";

// BCO/USA
import bcousa1 from "../assets/units/BCO_USA/arena.png";
import bcousa2 from "../assets/units/BCO_USA/clube.png";

// ORLANDO
import orlando1 from "../assets/units/ORLANDO/quadra-2.png";
import orlando2 from "../assets/units/ORLANDO/quadra.png";
import orlando3 from "../assets/units/ORLANDO/bar-1.png";

// VILLA LOBOS
import villalobos1 from "../assets/units/VILLALOBOS/bar-1.jpg";
import villalobos2 from "../assets/units/VILLALOBOS/bar-2.jpg";
import villalobos3 from "../assets/units/VILLALOBOS/mesa.jpg";
import villalobos4 from "../assets/units/VILLALOBOS/mesa-2.jpg";
import villalobos5 from "../assets/units/VILLALOBOS/mesanino.jpg";
import villalobos6 from "../assets/units/VILLALOBOS/mesanino2.jpg";
import villalobos7 from "../assets/units/VILLALOBOS/mesanino3.jpg";
import villalobos8 from "../assets/units/VILLALOBOS/quadra-2.jpg";
import villalobos9 from "../assets/units/VILLALOBOS/quadra-3.jpg";
import villalobos10 from "../assets/units/VILLALOBOS/quadra.jpg";

// ACLIMACAÇÃO
import aclimacao1 from "../assets/units/aclimacao/1.jpeg";
import aclimacao2 from "../assets/units/aclimacao/2.jpeg";
import aclimacao3 from "../assets/units/aclimacao/3.jpeg";
import aclimacao4 from "../assets/units/aclimacao/4.jpeg";
import aclimacao5 from "../assets/units/aclimacao/5.jpeg";
import aclimacao6 from "../assets/units/aclimacao/6.jpeg";
import aclimacao7 from "../assets/units/aclimacao/7.jpeg";

export const unidades = [
    {
        unidade: "Riplay Alto do ipiranga",
        endereco: "Rua Gama Lobo, 1475 – Vila Dom Pedro I",
        cidade: "São Paulo - SP",
        regiao: "Ipiranga",
        whatsapp: "https://wa.me/+5511939012833",
        imagens: [
            altoQuadra,
            altoQuadra_2,
            altoChurrasqueira,
            altoLoja,
            altoMesanino,
            altoMesas,
            altoBar,
        ],
        beachtennis: false,
        futevolei: true,
        beachvolei: true,
        funcional: true,
        position: { lat: -23.597027039094062, lng: -46.61200260245193 },
    },
    {
        unidade: "Riplay Granja Viana",
        endereco: "Estrada da Aldeia, 699 – Parque Frondoso",
        cidade: "Cotia - SP",
        regiao: "Granja Viana",
        whatsapp: "https://wa.me/+5511950333332",
        imagens: [
            granja1,
            granja2,
            granja3,
            granja4,
            granja5,
            granja6,
            granja7,
        ],
        beachtennis: true,
        futevolei: true,
        beachvolei: true,
        funcional: true,
        position: { lat: -23.585111318129783, lng: -46.82645600600979 },
    },
    {
        unidade: "Riplay Ipiranga",
        endereco: "Rua Conde Vicente de Azevedo, 73 – Vila Monumento",
        cidade: "São Paulo - SP",
        regiao: "Ipiranga",
        whatsapp: "https://wa.me/+5511963581877",
        imagens: [
            ipiranga1,
            ipiranga2,
            ipiranga3,
            ipiranga4,
            ipiranga5,
            ipiranga6,
            ipiranga7,
        ],
        beachtennis: false,
        futevolei: true,
        beachvolei: true,
        funcional: false,
        position: { lat: -23.585894906388425, lng: -46.61165909679635 },
    },
    {
        unidade: "Riplay Ipiranga Beach Tennis",
        endereco: "R. Moreira e Costa 474 - Ipiranga",
        cidade: "São Paulo - SP",
        regiao: "Ipiranga",
        whatsapp: "https://wa.me/+5511912697512",
        imagens: [
            ipirangaTennis1,
            ipirangaTennis2,
            ipirangaTennis3,
            ipirangaTennis4,
            ipirangaTennis5,
            ipirangaTennis6,
            ipirangaTennis7,
            ipirangaTennis8,
            ipirangaTennis9,
            ipirangaTennis10,
        ],
        beachtennis: true,
        futevolei: false,
        beachvolei: false,
        funcional: false,
        position: { lat: -23.5896870611586, lng: -46.60989382881243 },
    },
    // {
    //     unidade: "Riplay Villa Lobos",
    //     endereco: "Av. Queiroz Filho, 491 - Villa Lobos",
    //     cidade: "São Paulo - SP",
    //     regiao: "Villa Lobos",
    //     whatsapp: "https://wa.me/+5511911460554",
    //     imagens: [
    //         villalobos10,
    //         villalobos9,
    //         villalobos8,
    //         villalobos7,
    //         villalobos6,
    //         villalobos5,
    //         villalobos4,
    //         villalobos3,
    //         villalobos2,
    //         villalobos1,
    //     ],
    //     beachtennis: true,
    //     futevolei: true,
    //     beachvolei: true,
    //     funcional: true,
    //     position: { lat: -23.537350045825757, lng: -46.72489468199729 },
    // },
    {
        unidade: "Arena Riplay Makkai Pirituba",
        endereco: "Av. Menotti Laudisio 734",
        cidade: "Pirituba - SP",
        regiao: "Jardim",
        whatsapp: "https://wa.me/+5511978387370",
        imagens: [
            santoAndreQuadra,
            santoAndrePrainha,
            santoAndreBar,
            santoAndreChurrasqueira,
            santoAndreMesas,
            santoAndreParque,
        ],
        beachtennis: true,
        futevolei: true,
        beachvolei: true,
        funcional: false,
        position: { lat: -23.668496549043276, lng: -46.51171265592017 },
    },
    {
        unidade: "Riplay Orlando/USA",
        endereco: "6700 Kingspointe Pkwy Orlando, Florida",
        cidade: "Orlando, FL",
        regiao: "Estados Unidos",
        whatsapp: "https://wa.me/+5511939010541",
        imagens: [orlando1, orlando2, orlando3],
        beachtennis: true,
        futevolei: true,
        beachvolei: true,
        funcional: false,
        position: { lat: 28.506730539625405, lng: -81.44339023660783 },
    },
    // {
    //     unidade: "Riplay BCO/USA",
    //     endereco: "6700 Kingspointe Pkwy",
    //     cidade: "Orlando, FL 32819",
    //     regiao: "Estados Unidos",
    //     whatsapp: "https://wa.me/+5511939010541",
    //     imagens: [bcousa1, bcousa2],
    //     beachtennis: true,
    //     futevolei: true,
    //     beachvolei: true,
    //     funcional: false,
    //     position: { lat: 28.45785086169774, lng: -81.43836994191457 },
    // },
    {
        unidade: "Riplay Aclimação",
        endereco: "Rua Robertson, 303 - Cambuci",
        cidade: "São Paulo - SP",
        regiao: "Aclimação",
        imagens: [aclimacao1, aclimacao2, aclimacao3, aclimacao4, aclimacao5, aclimacao6,aclimacao7],
        beachtennis: true,
        futevolei: true,
        beachvolei: true,
        funcional: false,
        position: { lat: -23.614684481381804, lng: -46.71381011058009 },
        isCommingSoon: false,
        whatsapp: "https://wa.me/+5511911923581",
    },
];

export default unidades;
